// custom typefaces
/* import "typeface-montserrat"
import "typeface-merriweather"
 */
/* require(`katex/dist/katex.min.css`) */

// gatsby-browser.js


import React from 'react'
import posed, { PoseGroup } from 'react-pose'

/* const Transition = posed.div({
  enter: { opacity: 1, filter: 'blur(0px)', delay: 0, beforeChildren: true },
  exit: { opacity: 0, filter: 'blur(20px)', y: 0},
}) */

/* const Transition = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 },
}) */

/* export const replaceComponentRenderer = ({ props, ...other }) => {
  const { component } = props.pageResources
  return (
      <PoseGroup>
        <Transition key={props.location.key}>
          {React.createElement(component, props)}
        </Transition>
      </PoseGroup>
  )
} */

// trigger an immediate page refresh when an update is found
export const onServiceWorkerUpdateReady = () => window.location.reload();