module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fathom/gatsby-browser.js'),
      options: {"plugins":[],"trackingUrl":"fathom.malloc.hr","siteId":"EPXPC","whitelistHostnames":["niksamihaica.from.hr","www.niksamihaica.from.hr"]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":760},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-83015711-3","head":false,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nikša Mihaice | Personal Website","short_name":"Portfolio","start_url":"/","background_color":"#ffffff","theme_color":"#464646","display":"minimal-ui","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
